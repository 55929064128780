import LoginAsUserButton from "./LoginAsUserButton";
import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import { DeleteWithConfirmButton, TextField } from "react-admin";

const UserList = () => {
  return (
    <ListGuesser hasCreate={false}>
      <TextField source="originId" label="ID" sortBy="id" />
      <FieldGuesser source="email" />
      <FieldGuesser source="createdAt" />
      <FieldGuesser source="confirmed" sortable={false} />
      <FieldGuesser source="roles" sortable={false} />
      <LoginAsUserButton />
      <DeleteWithConfirmButton />
    </ListGuesser>
  );
};

export default UserList;
