import { ListGuesser } from "@api-platform/admin";
import { DateInput } from "react-admin";

const filters = [
  <DateInput label="from" source="from" alwaysOn={true} />,
  <DateInput label="to" source="to" alwaysOn={true} />,
];

const List = () => {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const formatDate = (date: Date) => {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0")
    );
  };

  return (
    <ListGuesser
      hasCreate={false}
      hasShow={false}
      hasEdit={false}
      filters={filters}
      filterDefaultValues={{
        from: formatDate(firstDay),
        to: formatDate(lastDay),
      }}
    ></ListGuesser>
  );
};

export default List;
