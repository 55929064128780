import {
  hydraDataProvider,
  fetchHydra as baseFetchHydra,
} from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";

const token = () => {
  const auth = localStorage.getItem("auth");
  if (!auth) return;
  return JSON.parse(auth).token;
};

const getHeaders = (): HeadersInit => {
  const userToken = token();
  return {
    Authorization: `Bearer ${userToken}`,
  };
};

const httpClient = (url: URL, options: any = {}) => {
  return baseFetchHydra(url, {
    ...options,
    headers: getHeaders,
  });
};

const apiDocumentationParser = async (entrypointUrl: string) => {
  try {
    return await parseHydraDocumentation(entrypointUrl, {
      headers: getHeaders,
    });
  } catch (result: any) {
    const { api, response, status } = result;

    if (status !== 401 || !response) {
      throw result;
    }

    localStorage.removeItem("auth");

    return {
      api,
      response,
      status,
    };
  }
};

const dataProvider = (entrypoint: string) =>
  hydraDataProvider({
    entrypoint: entrypoint,
    httpClient: httpClient,
    apiDocumentationParser: apiDocumentationParser,
  });

export default dataProvider;
