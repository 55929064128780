import { AuthProvider } from "react-admin";

interface LoginIntefrace {
  username: string;
  password: string;
}

const checkTokenRoles = (token:string) => {
    const jwt = JSON.parse(atob(token.split('.')[1]));

    return jwt.roles.find((role: string) => role === 'ROLE_ADMIN');
}
const authProvider: AuthProvider = {
    login: ({ username, password }: LoginIntefrace) => {
        const request = new Request(
            process.env.REACT_APP_API_URL + "/login_check",
            {
                method: "POST",
                body: JSON.stringify({ username, password }),
                headers: new Headers({ "Content-Type": "application/json" }),
            }
        );
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((auth) => {
                if(!checkTokenRoles(auth.token)) {
                    throw new Error("Access denied");
                }
                localStorage.setItem("auth", JSON.stringify(auth));
            })
            .catch(() => {
                throw new Error("Network error");
            });
    },
    checkAuth: () => {
        const auth = localStorage.getItem("auth");
        
        if (!auth) {
            return Promise.reject({ message: 'login.required' });
        };

        const token = JSON.parse(auth);

        return checkTokenRoles(token.token) 
        ? Promise.resolve() 
        : Promise.reject({ message: 'login.required' });
    },
    getPermissions: () => {
        // Required for the authentication to work
        return Promise.resolve();
    },
    logout: function (params: any): Promise<string | false | void> {
        localStorage.removeItem('auth');
        return Promise.resolve('');
    },
    checkError: function (error: any): Promise<void> {
        throw new Error("Function not implemented.");
    }
};

export default authProvider;
