import { Button, useRecordContext } from "react-admin";

const loginAsUser = async (id: string) => {
  const auth = localStorage.getItem("auth");
  const token = JSON.parse(auth ?? ``).token;
  const request = new Request(
    process.env.REACT_APP_API_URL + "/users/{id}/token".replace("{id}", id),
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    }
  );
  return fetch(request)
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((auth) => {
      window.open(
        process.env.REACT_APP_URL +
          "/login-as-user?token=:token&refreshToken=:refreshToken"
            .replace(":token", auth.token)
            .replace(":refreshToken", auth.refreshToken),
        "_blank"
      );
    })
    .catch(() => {
      throw new Error("Network error");
    });
};

const LoginAsUserButton = () => {
  const record = useRecordContext();

  return (
    <Button
      label="Login as user"
      onClick={() => {
        const id = record.originId;

        loginAsUser(id);
      }}
    />
  );
};

export default LoginAsUserButton;
