import "./App.css";
import { HydraAdmin, ResourceGuesser } from "@api-platform/admin";
import authProvider from "./authProvider";
import UserList from "./components/users/UserList";
import dataProvider from "./dataProvider";
import List from "./components/usageLogs/List";
import BusinessUsageList from "./components/businessUsage/List";

const entrypoint: string = process.env.REACT_APP_API_URL || "";

function App() {
  return (
    <HydraAdmin
      entrypoint={entrypoint}
      authProvider={authProvider}
      dataProvider={dataProvider(entrypoint)}
      requireAuth
    >
      <ResourceGuesser name="users" list={UserList} />
      <ResourceGuesser name="usage_logs" list={List} />
      <ResourceGuesser
        name="businesses/usage-logs"
        options={{ label: "Business logs" }}
        list={BusinessUsageList}
      />
    </HydraAdmin>
  );
}

export default App;
