import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import { DateField, FunctionField } from "react-admin";
import { UsageLogs } from "./type";

const List = () => {
  return (
    <ListGuesser hasCreate={false} hasShow={false} hasEdit={false}>
      <FunctionField
        label="Business"
        source="business"
        render={(record: UsageLogs) =>
          `${record.business.replace("/businesses/", "")}`
        }
        sortable={true}
      />
      <FieldGuesser source="model" sortable={false} />
      <FieldGuesser source="type" sortable={false} />
      <FieldGuesser source="inputTokens" sortable={false} />
      <FieldGuesser source="outputTokens" sortable={false} />
      <FieldGuesser source="duration" />
      <DateField source="createdAt" showTime={true} />
      <FunctionField
        label="Cost"
        source="cost"
        render={(record: UsageLogs) => `$${record.cost}`}
        sortable={true}
      />
    </ListGuesser>
  );
};

export default List;
